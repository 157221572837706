<template>
  <div>
    <page-header></page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="12">
            <el-form ref="editForm" label-width="200px">
              <el-form-item label="选择角色">
                <el-select v-model="currentRoleId" placeholder="角色" @change="handleRoleChange">
                  <el-option v-for="(item,index) in roles" :label="item.name" :value="item.id"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择资源">
                <el-tree
                    class="resource-tree"
                    :props="columns"
                    node-key="id"
                    ref="tree"
                    :data="treeData"
                    :show-checkbox="true"
                    :default-expand-all="true"
                    :expand-on-click-node="true"
                    :check-strictly="true"
                    highlight-current>
                </el-tree>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSaveRoleResource()" :loading="saveLoading">保存设置</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { SysResourcesApi, SysRolesApi } from '@/api'

export default {
  name: 'RoleResource',
  data () {
    return {
      columns: {
        id: 'id',
        label: 'name'
      },
      treeData: [],
      roles: [],
      currentRoleId: '',
      saveLoading: false
    }
  },
  methods: {
    initData () {
      SysResourcesApi.getAll().then(data => {
        this.treeData = data
      })

      SysRolesApi.getAll().then(res => {
        this.roles = res
      })
    },
    handleRoleChange (id) {
      SysRolesApi.getResource(id).then(result => {
        this.$refs.tree.setCheckedKeys(result)
      })
    },
    handleSaveRoleResource () {
      const resourceIds = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
      if (resourceIds.length === 0) {
        this.$message.error('请设置资源')
        return
      }
      this.saveLoading = true
      SysRolesApi.saveResource({
        id: this.currentRoleId,
        resourceIds: resourceIds
      }).then(() => {
        this.saveLoading = false
        this.$message.success('保存成功')
      }).catch(() => {
        this.saveLoading = false
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style scoped>
.resource-tree {
  overflow-y: auto;
  height: 600px;
}
</style>
